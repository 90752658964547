const Page1Style = theme => ({
  root: {
    flex: 1,
    flexWrap: 'wrap',
    minHeight: '100vh',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
})

export default Page1Style;