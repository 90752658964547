// src/pages/Content/Content.jsx
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import NavbarLeftLogo from '../../components/NavbarLeftLogo/NavbarLeftLogo';
import axios from 'axios';

const Content = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('https://your-api-url.com/data')
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          paddingTop: '100px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          paddingTop: '100px',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'red',
        }}
      >
        <p>Error loading data: {error.message}</p>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingTop: '100px',
      }}
    >
      <NavbarLeftLogo />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2rem',
          color: 'white',
        }}
      >
        <h1>Content Page</h1>
        <ul>
          {data.map(item => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export default Content;