// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Page1 from './pages/Page1/Page1';
import Content from './pages/Content/Content';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Page1/> } />
        <Route path="/content" element={ <Content/> } />
      </Routes>
    </div>
  )
}

export default App;