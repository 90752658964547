import React from 'react';
import Page1Style from './Page1Style';
import NavbarLeftLogo from 'components/NavbarLeftLogo/NavbarLeftLogo';
import HeroWithText from 'components/HeroWithText/HeroWithText';
import { withStyles } from '@material-ui/core/styles';

const Page1 = props => {
  const {classes} = props;

  return (
    <div className={classes.root}>
      <NavbarLeftLogo/>
      <HeroWithText/>
    </div>
  );
};


export default withStyles(Page1Style)(Page1);