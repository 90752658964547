// src/components/HeroWithText/HeroWithText.jsx
import React from 'react';
import { Box, Typography } from '@mui/material';

const HeroWithText = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%', // Ensure it spans the full width
        height: '981px', // Ensure it spans the full height
        backgroundImage: 'url(/images/mountain-7690893.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', // Ensure the image doesn't repeat
        textAlign: 'center',
        color: 'white',
      }}
    >
      <img
        src="/logo name white transparent background.png"
        alt="OrbitPATH Logo"
        style={{ maxHeight: '500px', marginBottom: '20px', maxWidth: '90%', width: 'auto', height: 'auto'}} // Adjust the height as needed
      />
      <Typography variant="p">
        Military grade Space Domain Awareness enabling satellite operators to survive, sustain, and fight on the modern space battlefield.
      </Typography>
    </Box>
  );
};

export default HeroWithText;