// src/components/NavbarLeftLogo/NavbarLeftLogo.jsx
import React from 'react';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import { Link } from 'react-router-dom';

const NavbarLeftLogo = () => {
  return (
    <AppBar position="fixed" sx={{ bgcolor: '#000000', height: '100px', zIndex: 1000 }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src="/logo name white transparent background no smalltext.png"
            alt="OrbitPATH Logo"
            style={{ height: '80px' }}
          />
        </Box>
        <Box>
          <IconButton color="inherit" component={Link} to="/" sx={{ height: '50px', width: '50px' }}>
            <HomeIcon sx={{ fontSize: '50px' }} />
          </IconButton>
          <IconButton color="inherit" component={Link} to="/content" sx={{ height: '50px', width: '50px' }}>
            <SatelliteAltIcon sx={{ fontSize: '40px' }} />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavbarLeftLogo;